import citiesRu from './citiesRu';
import countriesRu from './countriesRu';

const ru = {
  ...countriesRu,
  ...citiesRu,
  vacancies: 'Вакансии',
  edit: 'Редактировать',
  'create vacancy': 'Создать вакансию',
  search: 'Поиск',
  filter: 'Фильтр',
  'reset filter': 'Cбросить фильтр',
  loading: 'Загрузка...',
  candidates: 'Кандидаты',
  clients: 'Клиенты',
  managers: 'Менеджеры',
  recruiters: 'Рекрутеры',
  agents: 'Агенты',
  'hr directors': 'HR Директоры',
  teams: 'Команды',
  'my teams': 'Мои команды',
  reports: 'Отчеты',
  'my reports': 'Мои отчеты',
  'recruiters reports': 'Отчеты рекрутеров',
  'weekly reports': 'Еженедельные отчеты',
  analytics: 'Аналитика',
  finance: 'Финансы',
  chat: 'Чат',
  Active: 'Активна',
  Hold: 'Пауза',
  Published: 'Опубликована',
  Closed: 'Закрыта',
  'company name': 'Название компании',
  'company website': 'Сайт компании',
  'contact person name': 'Имя контактного лица',
  'contact person': 'Контактное лицо',
  email: 'Email',
  messenger: 'Название мессенджера',
  nickname: 'Данные мессенджера',
  'working conditions': 'Условия оплаты',
  'vacancy working conditions': 'Условия работы',
  'working conditions title': 'Условия работы',
  'working conditions first part': 'Первая часть',
  'working conditions first part placeholder': '80% по факту выхода',
  'working conditions second part': 'Вторая часть',
  'working conditions second part placeholder':
    '20% после испытательного срока (90 дней)',
  'first part': 'первая половина',
  'second part': 'вторая половина',
  'add messenger': 'Добавить мессенджер',
  manager: 'Meнеджер',
  annual: 'Годовой процент',
  'contact person position': 'Должность',
  phone: 'Телефон',
  'company logo': 'Логотип компании',
  'workspace photos': 'Фото рабочего места',
  'company photos': 'Фотографии компании',
  'rest zone photos': 'Фото зоны отдыха',
  'team photos': 'Фото команды',
  'a video link': 'Ссылка на видео',
  companies: 'Компании',
  'new company': 'Новая компания',
  save: 'Сохранить',
  publicate: 'Опубликовать',
  cancel: 'Отмена',
  create: 'Создать',
  delete: 'Удалить',
  country: 'Страна',
  city: 'Город',
  category: 'Рубрика',
  subcategory: 'Подрубрика',
  currency: 'Валюта',
  'publication period': 'Время публикации',
  'new vacancy': 'Новая вакансия',
  'vacancy title': 'Заголовок вакансии',
  duties: 'Обязанности',
  salary: 'Заработная плата',
  'salary shortcut': 'З/П',
  'interview stages': 'Этапы интервью',
  company: 'Компания',
  'vacancy description': 'Описание вакансии',
  'maximum 5000 characters': 'Максимум 5000 символов',
  keywords: 'Ключевые слова / теги',
  'add presentation': '+ Добавить презентацию',
  presentation: 'Презентация',
  page: 'Страница',
  next: 'Следующая',
  previous: 'Предыдущая',
  'sort by': 'Cортировать:',
  published: 'Опубликована',
  'go to full description': 'Перейти к полному описанию',
  'the name': 'Название',
  location: 'Локация',
  status: 'Статус',
  mth: 'мес.',
  from: 'от',
  to: 'до',
  apply: 'Применить',
  tags: 'Ключевые слова',
  reset: 'Сбросить',
  return: 'Вернуться',
  'photos of the workplace': 'Фото рабочeго места',
  'photos of the team': 'Фото команды',
  'photos of the recreation area': 'Фото зоны отдыха',
  checklist: 'Чек-лист',
  'date of publication': 'Cрок публикации',
  'vacancy presentation': 'Презентация вакансии',
  vacancy: 'Вакансия',
  'up to': 'До',
  'add client': 'Добавить клиента',
  latest: 'Сначала новые',
  oldest: 'Сначала старые',
  'alphabet (A-Z)': 'По алфавиту (А-Я)',
  'alphabet (Z-A)': 'По алфавиту (Я-А)',
  'most applied': 'Большее количество откликов',
  'least applied': 'Меньшее количество откликов',
  password: 'Пароль',
  'confirm password': 'Подтвердите пароль',
  'max one': 'Максимум одно фото',
  'max five': 'Максимум пять фото',
  details: 'Реквизиты для выставления счета',
  'must be one hundred': 'Должно быть 100% в сумме',
  contract: 'Договор',
  'add video link': 'Добавить ссылку на YouTube видео',
  'add contract': 'Добавить контракт',
  'per month': 'в месяц',
  'company did not upload workplace photos':
    'Компания не загрузила фотографии рабочего места',
  'company did not upload photos of the recreation area':
    'Компания не загрузила фотографии зоны отдыха',
  'company did not upload team photos': 'Компания не загрузила фото команды',
  'company hasn’t uploaded the video yet': 'Компания еще не загрузила видео',
  'are you sure you want to delete the company':
    'Вы уверены, что хотите удалить компанию: ',
  'are you sure you want to delete the vacancy':
    'Вы уверены, что хотите удалить вакансию: ',
  'are you sure you want to delete the recruiter':
    'Вы уверены, что хотите удалить рекрутера: ',
  'are you sure you want to delete the hrd':
    'Вы уверены, что хотите удалить HR директора: ',
  'are you sure you want to delete the manager':
    'Вы уверены, что хотите удалить менеджера: ',
  'are you sure you want to unlink the candidate':
    'Вы уверены, что хотите отвязать кандидата:  ',
  'are you sure you want to delete the team':
    'Вы уверены, что хотите удалить команду: ',
  'Enter your name': 'Введите имя',
  'Name is too long': 'Имя слишком длинное',
  'Enter company name': 'Введите название компании',
  'Select a category': 'Выберите рубрику',
  'Select a subcategory': 'Выберите подрубрику',
  'Select a country': 'Выберите страну',
  'Select a city': 'Выберите город',
  'Add a description': 'Добавьте описание',
  'Description is too long': 'Описание слишком длинное',
  'Add responsibilities': 'Добавьте обязанности',
  'Too many characters': 'Слишком много символов',
  'Add interview stages': 'Добавьте этапы интервью',
  'Add a salary': 'Добавьте зарплату',
  'Enter a figure of 10 characters max': 'Поле зарплата слишком длинное',
  'Add terms': 'Добавьте условия работы',
  'Enter a position': 'Добавьте позицию',
  'Select keywords': 'Выберите ключевые слова',
  'Select currency': 'Выберите валюту',
  'Select activity period': 'Выберите период публикации',
  'Upload a presentation file': 'Загрузите презентацию',
  'Max file size is 30 Mb': 'Максимальный размер файла 30 мегабайт',
  'Select a messenger': 'Выберите мессенджер',
  'Enter your username': 'Введите ваш никнэйм',
  'Enter your email': 'Введите ваш email',
  'Invalid email': 'Невалидный email',
  'Email is not available': 'Email уже занят',
  'Enter a phone number': 'Введите номер телефона',
  'Number is too long': 'Телефон слишком длинный',
  'Invalid phone number': 'Неверный номер телефона',
  'Enter a company website': 'Введите адрес сайта компании',
  'Enter a contact person': 'Введите имя контактного лица',
  "Enter a contact person's title": 'Введите должность контактного лица',
  'Assign a manager': 'Выберите менеджера',
  'Select terms': 'Выберите условия труда',
  'Enter annual percentage rate': 'Введите годовой процент',
  'Annual percentage must be a number': 'Процент должен быть числом',
  'Select a logo': 'Выберите логотип',
  'Password is to short': 'Пароль слишком короткий',
  'Password is to long': 'Пароль слишком длинный',
  "Password's must match": 'Пароли должны совпадать',
  'Enter a password': 'Введите пароль',
  'Enter a percentage of first payment part':
    'Введите процент первой части оплаты',
  'Enter a percentage of second payment part':
    'Введите процент второй части оплаты',
  'Max file size is 10 Mb': 'Максимальный размер файла 10 мегабайт',
  'Enter a requisites': 'Введите реквизиты',
  'Upload a contract file': 'Загрузите контракт',
  'Invalid website': 'Неверный формат адреса сайта',
  'do you really want to delete': 'Вы действительно хотите удалить?',
  'are you sure you want to delete the staff member': 'Вы уверены, что хотите удалить сотрудника {{name}}?',
  'this empoyee already works for': 'Этот работник уже работает в {{companies}}',
  'would you like to add to company': 'Хотели бы вы добавить {{employeeName}} в эту компанию?',
  'Invalid url': 'Неверная ссылка',
  'In progress': 'В процессе',
  'InProgress': 'В процессе',
  'OfferRefused': 'Отклонен кандидатом',
  'PreOffer': 'Пре-Согласован',
  'client type': 'Тип клиента',
  'client type tt': 'TT',
  'vacancy reward': 'Вознаграждение за вакансию',
  'recruiter reward': 'Вознаграждение рекрутеру',
  'client type percent': 'Процентный',
  'client type all': 'Все',
  Rejected: 'Отклонен',
  Offer: 'Согласован',
  'offer details': 'Детали согласования',
  'candidate name': 'Имя',
  'response date': 'Дата отклика',
  'recruiter name': 'Имя рекрутера',
  cv: 'Резюме',
  experience: 'Опыт',
  'best skills': 'Лучшие навыки',
  specialization: 'Направление',
  'name or email': 'Имя или почта',
  'no recruiter': 'Нет рекрутера',
  'no CV': 'нет резюме',
  'new candidate': 'Новый кандидат',
  'new candidate title': 'Добавить кандидата',
  'name (A-Z)': 'По имени (А-Я)',
  'name (Z-A)': 'По имени (Я-А)',
  'apply latest': 'По дате отклика (Новые)',
  'apply oldest': 'По дате отклика (Старые)',
  name: 'Имя',
  'apply date': 'Дата отклика',
  'current workplace': 'Текущее место работы',
  comment: 'Комментарий',

  'candidate was sended to manager':
    '{{authorFullName}} ({{role}}): Кандидат {{fullName}} был отправлен менеджеру',

  'candidate was sended to client':
    '{{authorFullName}} ({{role}}): Кандидат {{fullName}} был отправлен клиенту',

  'status was changed to In progress':
    '{{authorFullName}} ({{role}}): Статус {{fullName}} был изменен на "в процессе"',
  'status was changed to Rejected':
    '{{authorFullName}} ({{role}}): Статус {{fullName}} был изменен на "отклонен"',
  'status was changed to Hold':
    '{{authorFullName}} ({{role}}): Статус {{fullName}} был изменен на "пауза"',
  'status was changed to Offer':
    '{{authorFullName}} ({{role}}): Статус {{fullName}} был изменен на "согласован"',
  'candidate was added to vacancy': 'Кандидат был добавлен к вакансии',
  'yours comment': 'Ваш комментарий',
  'send to manager': 'Отправить менеджеру',
  'send to client': 'Отправить клиенту',
  candidate: 'Кандидат',
  activity: 'Активити',
  'place of work': 'Текущее место работы',
  Russia: 'Россия',
  Ukraine: 'Украина',
  unlink: 'Отвязать',
  'new recruiter': 'Новый рекрутер',
  'new recruiter title': 'Добавить рекрутера',
  team: 'Команда',
  'annual percentage': 'Годовой процент',
  requisites: 'Реквизиты',
  'reward for the listed candidates':
    'Вознаграждение за приведенных кандидатов',
  'reward for the closed position': 'Вознаграждение за закрытую вакансию',
  recruiter: 'Рекрутер',
  avatar: 'Аватар',
  "bigger recruiter's commission": "Ставка комиссии ррекрутера для компаний-партнеров выше 13%",
  "lower recruiter's commission": "Ставка комиссии ррекрутера для компаний-партнеров ниже 13%",
  "senior bigger recruiter's commission": "Ставка комиссии сеньор ррекрутера для компаний-партнеров выше 13%",
  "senior lower recruiter's commission": "Ставка комиссии сеньор ррекрутера для компаний-партнеров ниже 13%",
  "subrecruiter's pay for a closed position": 'Вознаграждение cабрекрутеру',
  'payment details': 'Реквизиты для оплаты',
  'hr director name': 'Имя директора',
  'HRD Name': 'Имя HRD',
  'hr directors title': 'HR Директоры',
  'new hr director title': 'Добавить HR Директора',
  'assigned managers': 'Закрепленные менеджеры',
  'new hr director': 'Новый директор',
  yes: 'Да',
  no: 'Нет',
  'are you sure you want to cancel': 'Вы уверены что хотите отменить?',
  'your account': 'Личный кабинет',
  'sign out': 'Выйти из профиля',
  'comment is too long': 'Комментарий слишком длинный',
  'offer amount': 'Сумма оффера',
  'date of employment': 'Дата выхода',
  'date of probation end': 'Дата окончания испытательного срока',
  active: 'Активен',
  disabled: 'Отключен',
  'subrecruiter percentage': 'Процент сабрекрутеру',
  'new role': 'Новая роль',
  'hr director': 'HR Директор',
  hrDirector: 'HR Директор',
  'managers title': 'Менеджеры',
  'new manager title': 'Добавить менеджера',
  'new manager': 'Новый менеджер',
  'manager name': 'Имя менеджера',
  "bigger manager's commission": 'Ставка комиссии менеджера для компаний-партнеров выше 13%',
  "lower manager's commission": 'Ставка комиссии менеджера для компаний-партнеров ниже 13%',
  "bigger director's commission": 'Ставка комиссии HRD для компаний-партнеров выше 13%',
  "lower director's commission": 'Ставка комиссии HRD для компаний-партнеров ниже 13%',
  'teams title': 'Команды',
  "team's cash": 'Кэш команды',
  "team's size": 'Численность команды',
  'new team title': 'Создать команду',
  'team name': 'Название команды',
  'select from the list': 'Выберите из списка',
  'edit team title': 'Редактировать команду',
  'trial period end': 'Конец ИС',
  total: 'Всего',
  'team cash': 'Кэш команды',
  'add criterion': 'Добавить критерий',
  'must have checkbox': 'Обязательный',
  'must have': 'Обязательные',
  'optional': 'Необязательные',
  'mark the matches': 'Отметьте соответствия',
  close: 'Закрыть',
  send: 'Отправить',
  'no requirements': 'Нет требований',
  for: 'За',
  offer: 'Оффер',
  'the client did not add requirements': 'Клиент не добавил требований',
  invalidApplyDate: 'Неправильная дата',
  'wrong date': 'Неправильная дата',
  invalidYear: 'Выберите текущий год или выше',
  description: 'Описание',
  'one week': 'Одна неделя',
  month: 'Месяц',
  'two months': 'Два месяца',
  'three months': 'Три месяца',
  year: 'Год',
  '1 week': '1 неделя',
  '1 month': '1 месяц',
  '2 months': '2 месяца',
  '3 months': '3 месяца',
  'employment date': 'Дата выхода на работу',
  share: 'Поделиться',
  'you have successfully shared the vacancy': 'Вы успешно поделились вакансией',
  'staff': 'Сотрудники',
  'new employee title': 'Новый сотрудник',
  'employee name': 'Имя сотрудника',
  employees: 'Сотрудники',
  'allow Client add colleagues': 'Разрешить клиенту добавлять сотрудников',
  notifications: 'Уведомления',
  settings: 'Настройки',
  'see all': 'Посмотреть все',
  'mark all as read': 'Отметить все как прочитанные',
  'no notifications': 'Нет уведомлений',
  'status has been updated': 'статус был обновлен',
  'new comment to': 'Добавлен новый комментарий к',
  'additional information': 'Дополнительная информация',
  'checklist link': 'Ссылка на чек-лист',
  restore: 'Активировать',
  'candidates sent to clients': 'Список кандидатов',
  'offers': 'На согласование',
  'add comment': 'Добавить комментарий',
  'expand': 'Развернуть',
  'collapse': 'Свернуть',
  'annual statistics': 'Годовая статистика',
};

export default ru;
